<template>
  <div class="containerHistory">
    <!-- <div class="first">
      <img src="../assets/history/redromb.png" alt="" />
    </div> -->
    <div class="second">
      <img src="../assets/history/angar.png" alt="" />
    </div>
    <div class="tres">
      <img src="../assets/history/fon.png" alt="" />
      <div class="redline">
        <img src="../assets/history/redline.png" alt="" />
      </div>
      <div class="title" v-html="containerHistoryTitle"></div>
      <div class="subtitle" v-html="containerHistorySubTitle"></div>
    </div>
  </div>
  <div class="containerfilm">
    <div class="video" v-if="ShowVideo" @click="ShowVideo = false">
      <img src="../assets/history/film.png" alt="" />
      <div class="play">
        <img src="../assets/history/play.png" alt="" />
      </div>
    </div>
    <div class="videoplay" v-else>
      <video autoplay loop controls class="myVideo">
        <source src="../assets/video/zpfilm.mp4" type="video/mp4" />
      </video>
      <!-- <iframe
        width="1178"
        height="563"
        src="https://www.youtube.com/embed/uCNWuSbPnt4"
        title="YouTube video player"
        frameborder="0"
        autoplay="1"
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe> -->
    </div>
    <div class="redromb">
      <img src="../assets/history/redrombfilm.png" alt="" />
      <div class="title">Посмотрите фильм о нас</div>
    </div>
  </div>
  <div class="containercitata">
    <div class="title" v-html="containercitata_title"></div>
  </div>
  <div class="containerphoto">
    <div class="img2">
      <img src="../assets/history/img2.png" alt="" />
    </div>
    <div class="img3">
      <img src="../assets/history/img3.png" alt="" />
    </div>
    <div class="img4">
      <img src="../assets/history/img4.png" alt="" />
    </div>
    <div class="img5"><img src="../assets/history/img5.png" alt="" /></div>
    <div class="img6">
      <img src="../assets/history/img6.png" alt="" />
    </div>
    <div class="img7">
      <img src="../assets/history/img7.png" alt="" />
    </div>
    <div class="title1" v-html="containerphoto_title1"></div>
    <div class="title2" v-html="containerphoto_title2"></div>
  </div>
</template>
<script>
export default {
  name: "history",
  data() {
    return {
      ShowVideo: true,
      containerHistoryTitle: "",
      containerHistorySubTitle: "",
      containercitata_title: "",
      containerphoto_title1: "",
      containerphoto_title2: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const res = await fetch("https://cb96744.tmweb.ru/api/articles/");
      const finalRes = await res.json();
      this.containerHistoryTitle = finalRes[11].text;
      this.containerHistorySubTitle = finalRes[12].text;
      this.containercitata_title = finalRes[13].text;
      this.containerphoto_title1 = finalRes[14].text;
      this.containerphoto_title2 = finalRes[15].text;
    },
  },
};
</script>
<style scoped>
.containerHistory {
  position: relative;
  width: 100%;
  height: 740px;
}
.containerHistory .first {
  position: absolute;
  right: 68px;
  top: 57px;
}
.containerHistory .first img {
  width: 714px;
}
.containerHistory .second {
  position: absolute;
  /* right: 134px; */
  top: 50px;
  width: 100%;
  text-align: center;
}
.containerHistory .second img {
  width: 345px;
  filter: drop-shadow(0px 10px 30px rgba(0, 0, 0, 0.2));
  border-radius: 10px;
}
.containerHistory .tres {
  position: absolute;
  top: 165px;

  z-index: -1;
}
.containerHistory .tres img {
  width: 375px;
  height: 512px;
}
.containerHistory .tres .redline {
  position: absolute;
  top: 225px;
  left: -40px;
}
.containerHistory .tres .redline img {
  width: 130px;
  height: 0px;
  border: 3px solid #ff4b00;
  transform: rotate(90deg);
}
.containerHistory .tres .title {
  position: absolute;
  top: 168px;
  left: 39px;
  width: 321px;
  font-family: RFD-Light;
  font-weight: normal;
  font-size: 20px;
  line-height: 130%;
  color: white;
}
.containerHistory .tres .subtitle {
  position: absolute;
  top: 360px;
  left: 39px;
  width: 313px;
  font-family: RFD-Light;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  color: white;
}
.bF {
  font-family: RFD-Regular;
  font-weight: bold;
}
.containerfilm {
  position: relative;
  width: 375px;
  margin: 0 auto;
  height: 310px;
  z-index: 1;
}
.containerfilm .video img {
  width: 100%;
}
.containerfilm .video .play {
  position: absolute;
  top: 110px;
  left: 130px;
}
.containerfilm .video .play img {
  width: 100px;
}
.containerfilm .redromb {
  position: absolute;
  right: 0px;
  top: 270px;
}
.containerfilm .redromb img {
  width: 227px;
}
.containerfilm .redromb .title {
  font-family: RFD-Regular;
  font-size: 18px;
  width: 171px;
  text-align: center;
  position: absolute;
  top: 20px;
  left: 30px;
  color: white;
}
.containerfilm .videoplay {
  position: absolute;
  z-index: -1;
  top: 0px;
  left: 0px;
  height: 563px;
}
.containerfilm .videoplay .myVideo {
  position: absolute;
  z-index: -1;
  top: 0px;
  left: 0px;
  width: 375px;
  /* height: 563px; */
}

.containercitata {
  position: relative;
  margin: 0 auto;
  background-color: #f0f0f0;
  width: 375px;
  height: 405px;
  /* top: -200px; */
  z-index: -2;
}
.containercitata .redline {
  position: absolute;
  top: 337px;
  left: 85px;
}
.containercitata .redline img {
  width: 234px;
  height: 0px;
  border: 3px solid #ff4b00;
  transform: rotate(90deg);
}
.containercitata .title {
  font-family: RFD-Light;
  font-size: 18px;
  width: 340px;
  position: absolute;
  line-height: 130%;
  left: 17px;
  top: 79px;
}
.containercitata .title b {
  font-family: RFD-Regular;
  font-size: 30px;
}
.containerphoto {
  position: relative;
  margin: 0 auto;
  width: 375px;
  height: 1500px;
}
.containerphoto .img1 img {
  position: absolute;
  width: 877px;

  right: 130px;
}
.containerphoto .img2 img {
  position: absolute;
  width: 332px;
  left: 15px;
  top: 50px;
  filter: drop-shadow(0px 5px 30px rgba(0, 0, 0, 0.25));
  border-radius: 5px;
}
.containerphoto .img3 img {
  position: absolute;
  width: 239px;
  right: 15px;
  top: 280px;
  filter: drop-shadow(0px 5px 30px rgba(0, 0, 0, 0.25));
  border-radius: 5px;
  z-index: 1;
}
.containerphoto .img4 img {
  position: absolute;
  width: 332px;
  right: 15px;
  top: 820px;
  filter: drop-shadow(0px 5px 30px rgba(0, 0, 0, 0.25));
  border-radius: 5px;
}
.containerphoto .img5 img {
  position: absolute;
  width: 239px;
  left: 15px;
  top: 1050px;
  filter: drop-shadow(0px 5px 30px rgba(0, 0, 0, 0.25));
  border-radius: 5px;
  z-index: 1;
}
.containerphoto .img6 img {
  position: absolute;
  height: 155px;
  width: 244px;
  left: 45px;
  top: 1025px;
  z-index: 0;
}
.containerphoto .img7 img {
  position: absolute;
  width: 244px;
  right: 45px;
  top: 255px;
  z-index: 0;
  height: 155px;
}
.containerphoto .title1 {
  font-family: RFD-Light;
  font-size: 14px;
  position: absolute;
  line-height: 130%;
  right: 15px;
  width: 345px;
  top: 475px;
}
.containerphoto .title2 {
  font-family: RFD-Light;
  font-size: 14px;
  position: absolute;
  line-height: 130%;
  left: 15px;
  width: 342px;
  top: 1240px;
}
</style>
